.pageTitleDiv {
  background-color: #d2eefa;
  padding: 10px 20px;
  margin-top: -35px;
  margin-bottom: 15px;
  box-shadow: -3px 10px 5px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -3px 10px 5px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -3px 10px 5px -8px rgba(0, 0, 0, 0.75);
  border-right: 4px solid #0596dc;
}

.pageTitleDiv > p {
  font-size: 18px;
  font-weight: bold;
  color: #496778;
}

.contentOutLine {
  border: 1px solid #ccc;
  margin-top: 45px;
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
}

.filterFormField {
  display: flex;
  align-items: center;
}

.formLabel {
  font-size: 18px;
  font-weight: bold;
  width: 200px;
  margin-right: 5px;
  margin-bottom: 0px;
}
.headerMenu {
  background-color: #22b1e8;
  border-radius: 100px;
  margin: 15px auto;
  text-align: center;
}

.reportBox {
  background-image: url('../../assets/images/icons/13.png');
  text-align: center;
  height: 93px;
  width: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.reportNumber {
  color: #0598d7;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}

.reportlabel {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}
.top10Doctors {
  padding: 20px;
}
.downloadButton {
  background-image: url('../../assets/images/icons/11.png');
}
.docBox {
  background-image: url('../../assets/images/icons/13.png');
  text-align: left;
  height: '100%';
  width: '100%';
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 20px;
}
.numBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: start;
  padding-left: 0px;
  padding-right: 5px;
}
