@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");
html,
body {
  height: 100%;
  /* background-color: #f8f8f8; */
  font-family: "Montserrat", sans-serif;
}
a {
  color: #254181;
  text-decoration: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
a:focus-visible {
  outline: none;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus {
  border: 1px solid transparent;
  border-bottom: 2px solid #7367f0;
  color: #7367f0;
}
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
  border: none;
  border: 1px solid transparent;
  color: #2f2f2f;
}
.nav-tabs .nav-link {
  color: #2f2f2f;
  border: 1px solid transparent;
}
.btn-primary {
  color: #fff;
  background-color: #424cfd;
  border-color: #424cfd;
}
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #424cfd;
  border-color: #424cfd;
}
.btn-primary:hover,
.btn-primary:focus {
  color: #fff !important;
  background-color: #424cfd;
  border-color: #424cfd;
}
select.form-control {
  appearance: auto;
  -webkit-appearance: auto;
}
.close {
  display: none;
}
.link-dark,
.text-dark {
  color: #0e1333 !important;
}
.link-dark:hover {
  color: #424cfd;
}
a:hover {
  color: #424cfd !important;
}
.btn-outline-dark {
  color: #424cfd;
  border-color: #424cfd;
}
.btn-outline-dark:hover {
  color: #fff !important;
  background-color: #424cfd;
  border-color: #424cfd;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 8px 16px rgb(66 76 253 / 20%), 0 0 0 3px rgb(66 76 253 / 50%);
}
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #424cfd;
  border-color: #424cfd;
}
.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 8px 16px rgb(66 76 253 / 20%), 0 0 0 3px rgb(66 76 253 / 50%);
}
.mid-txt {
  max-width: 95%;
  margin: 0 auto;
  font-size: 20px;
  color: #808394;
}
.bg-blue {
  background: #424cfd;
}
.cust-pad {
  padding: 100px 30px;
}
.navbar-light .navbar-nav .nav-link {
  color: #0e1333;
  font-weight: 600;
  font-size: 14px;

  padding-right: 1rem;
  padding-left: 1rem;
}
.headtxt {
  font-size: 60px;
  font-weight: 700;
}
.footerlinks a {
  font-size: 14px;
  font-weight: 500;
  margin-top: 20px;
  color: #808394;
  display: inline-block;
}
.footerlinks h6 {
  color: #4e526a;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 8px;
}
.info {
  min-height: 100px;
  position: relative;
  border-radius: 6px;
  margin-top: 1em;
  -webkit-box-shadow: rgba(6, 20, 59, 0.2) 0 3px 10px;
          box-shadow: rgba(6, 20, 59, 0.2) 0 3px 10px;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.info:hover {
  -webkit-box-shadow: rgba(6, 20, 59, 0.2) 0 15px 10px;
          box-shadow: rgba(6, 20, 59, 0.2) 0 15px 10px;
  min-height: 155px;
  margin-top: -5px;
}

.info:hover::before {
  background-position-x: -75px;
}

.info::before {
  content: '';
  float: right;
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  left: 0;
  -webkit-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
}

.info::after {
  content: '';
  float: left;
  position: absolute;
  width: 36px;
  height: 36px;
  left: 20px;
  bottom: 20px;
}

/* .info1::after {
  background: url(../images/score_ic.png) no-repeat left bottom;
}

.info2::after {
  background: url(../images/NegativevMarks_ic.png) no-repeat left bottom;
}

.info3::after {
  background: url(../images/time_ic.png) no-repeat left bottom;
}

.info4::after {
  background: url(../images/target_ic.png) no-repeat left bottom;
} */

.info1 {
  background: #ec4880;
  background: linear-gradient(153deg, #ec4880 0%, #b954a4 100%);
}

.info2 {
  background: #8a5bd1;
  background: linear-gradient(156deg, #8a5bd1 0%, #2c15b2 100%);
}

.info3 {
  background: #ffb82c;
  background: linear-gradient(155deg, #ffb82c 0%, #f57f59 100%);
}

.info4 {
  background: #4ee858;
  background: linear-gradient(156deg, #4ee858 0%, #409500 100%);
}

.info5 {
  background: #46c5f2;
  background: linear-gradient(155deg, #46c5f2 0%, #6791db 100%);
}

.info6 {
  background: #ff8a46;
  background: linear-gradient(155deg, #ff8a46 0%, #db7867 100%);
}

.leftsidebar{
  background-color: #cccccc!important;
}

/* New Theme CSS */
.theme-background {
  /* background-color: #F9FAFF; */
  display: flex;
  overflow: hidden;
}

.new-page-title {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
}

.elevated-card{
  padding: 20px;
  background: #FFFFFF;
  /* Elevation/Cards */
  box-shadow: 0px 0px 9px rgba(151, 151, 155, 0.15);
}

.common-back-button{
  display: flex;
  color: #585858;
  align-items: center;
  /* margin-bottom: 20px; */
}

.common-back-button p{
  margin-bottom: 0px;
  margin-left: 10px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #585858
}

.common-back-button a{
  color: #585858!important
}