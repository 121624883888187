.dashboard-card{
    /* box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    height: 120px;
     */
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    padding:15px;
}

.box-1{
    background-color: #006666;
}
.box-2{
    background-color: #802000;
}
.box-3{
    background-color: #002b80;
}
.box-4{
    background-color: #732673;
}

.box-heading{
    color:#ffffff;
    font-size: 16px;
}
.box-value{
    color:#ffffff;
    font-size: 30px;
    font-weight: bold;
    text-align: right;
}


.dashboadcards{
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    height: 120px;
    padding:15px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
}
.value{
    font-size: 26px;
    color:#ffffff;
    font-weight: bold;
}

.label{
    font-size: 14px;
    color:#ffffff;
    margin-bottom: 0px;
}

.card1{
    background-color: #538cc6;
}

.card2{
    background-color: #ff1a75;
}

.card3{
    background-color: #ff9900;
}

.card4{
    background-color: #71c174;
}

.view-result-btn{
    font-size: 12px;
    border-radius: 100px;
    background-color: #2d5986;
    color:#ffffff;
    padding: 5px;
}
.view-result-btn:hover{color:#ffffff!important}
.center-align{
    text-align: center;
}
